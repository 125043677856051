import { debounce } from "lodash";
import { useState, useCallback } from "react";
import { PagedApiState } from "../api/useApiState";

export function useDebouncedFilter() {
    const [filterValueForInput, setFilterValueForInput] = useState<string>();
    const [filterValueForApi, setFilterValueForApi] = useState<string>();

    const debouncedSetFilter = useCallback(
        debounce((f: string) => setFilterValueForApi(f), 500, { leading: true }),
        [setFilterValueForInput]
    );
    const onFilterValueChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterValueForInput(e.target.value);
        debouncedSetFilter(e.target.value);
    }, [setFilterValueForInput, debouncedSetFilter]);

    return { filterValueForApi, filterValueForInput, onFilterValueChanged };
}

export function useFilterValueChangedHandler<T>(apiState: PagedApiState<T>, onFilterValueChanged: (e: React.ChangeEvent<HTMLInputElement>) => void) {
    return useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        onFilterValueChanged(e);
        apiState.goToPage(0);
    }, [apiState.goToPage, onFilterValueChanged]);
}