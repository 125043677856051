import { useCallback, useState } from 'react';
import { Map as LeafletMap, LatLngBounds } from 'leaflet';
import { BeaconWithPosition, FindBeaconInAreaRequest, LinearRing, useBeaconsInArea, useGetBeaconsInArea } from '../api';
import { Map, MapPosition } from './Map';
import { PolygonEditControl } from './PolygonEditControl';
import { Maximizable } from './Maximizable';
import { DrawingInstruction } from './DrawingInstruction';

export function boundsToArea(bounds: LatLngBounds): FindBeaconInAreaRequest | undefined {
    const north = bounds.getNorth();
    const east = bounds.getEast();
    const south = bounds.getSouth();
    const west = bounds.getWest();
    return {
        area: [
            { latitude: north!, longitude: west! },
            { latitude: north!, longitude: east! },
            { latitude: south!, longitude: east! },
            { latitude: south!, longitude: west! },
            { latitude: north!, longitude: west! }
        ]
    };
}

interface SelectBeaconsOnMapProps {
    instruction: string;
    selectedBeaconIds: string[];
    setSelectedBeaconIds(ids: string[]): void;
    height?: string | number;
    beacons?: BeaconWithPosition[];
    defaultMapPosition?: MapPosition;
}

export function SelectBeaconsOnMap(props: SelectBeaconsOnMapProps) {

    const { selectedBeaconIds, setSelectedBeaconIds } = props;
    const [mapArea, setMapArea] = useState<FindBeaconInAreaRequest>();
    const [area, setArea] = useState<LinearRing>();
    const [beaconsWithinSelectedArea, setBeaconsWithinSelectedArea] = useState<BeaconWithPosition[]>();
    const getBeaconsInArea = useGetBeaconsInArea();

    const [map, setMap] = useState<LeafletMap>();
    const [zoom, setZoom] = useState<number>();

    const isSelected = useCallback((beaconId: string) => {
        const index = selectedBeaconIds.indexOf(beaconId);
        return index >= 0;
    }, [selectedBeaconIds]);

    const onDraggedOrZoomed = useCallback((zoom: number, bounds: LatLngBounds) => {
        setMapArea(boundsToArea(bounds));
        setZoom(zoom);
    }, [setMapArea, setZoom]);


    const beaconsOnMap = useBeaconsInArea(zoom && zoom > 12 ? mapArea : undefined);
    const preloadedBeacons = props.beacons;

    const onUpdateSelectedBeaconsInArea = useCallback(async (areaRequest: FindBeaconInAreaRequest) => {
        let beacons = await getBeaconsInArea(areaRequest);
        if (beacons && preloadedBeacons) {
            beacons = beacons.filter(b => preloadedBeacons.some(p => p.id === b.id));
        }

        if (beacons) {
            setSelectedBeaconIds(beacons.map(b => b.id));
        }
        setBeaconsWithinSelectedArea(beacons);
    }, [getBeaconsInArea, setSelectedBeaconIds, setBeaconsWithinSelectedArea, preloadedBeacons]);

    const onAreaChanged = useCallback((newArea?: LinearRing) => {
        setArea(newArea);
        if (newArea) {
            onUpdateSelectedBeaconsInArea({ area: newArea });
        } else {
            setSelectedBeaconIds([]);
            setBeaconsWithinSelectedArea(undefined);
        }
    }, [onUpdateSelectedBeaconsInArea, setSelectedBeaconIds, setBeaconsWithinSelectedArea, setArea]);

    const beaconsToShow = props.beacons ? props.beacons.filter(b => !!b.position) : beaconsOnMap.value ?? beaconsWithinSelectedArea ?? [];

    return <Maximizable buttonPosition="bottom-right">
        <Map
        markers={beaconsToShow.map(b => ({
            lat : b.position.value.pos.lat,
            lon: b.position.value.pos.lon,
            title: b.serial || b.id,
            color: isSelected(b.id) ? 'green': 'blue'
        }))}
        allowNoMarkers
        onMapReady={setMap}
        onUserDraggedOrZoomed={onDraggedOrZoomed}
        defaultPosition={props.defaultMapPosition}
        controls={map ? <PolygonEditControl map={map} area={area} onAreaChanged={onAreaChanged} /> : undefined}/>
        <DrawingInstruction title={props.instruction} />
    </Maximizable>;
}