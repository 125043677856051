import { DeleteOutlined, EditOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Beacon, useAssignConstructionSite } from "../api";
import { ActionsIcon } from "./ActionsIcon";
import { Badge } from "./Badge";

interface ConstructionSiteActionMenuProps {
    refresh(): void;
    constructionProjectId: string;
    beacons: Beacon[];
    selectedBeaconIds?: string[];
    setSelectedBeaconIds?(ids: string[]): void;
    startEditing(): void;
}

export function ConstructionSiteActionMenu(props: ConstructionSiteActionMenuProps) {
    const { t } = useTranslation();
    const { refresh, beacons, constructionProjectId, selectedBeaconIds, setSelectedBeaconIds } = props;
    const assign = useAssignConstructionSite(constructionProjectId);

    const onDelete = useCallback(async () => {
        const others = (selectedBeaconIds || []).filter(i => !beacons.some(b => b.id === i));
        await assign({ beaconIds: beacons.map(b => b.id) });
        if (setSelectedBeaconIds) {
            setSelectedBeaconIds(others);
        }
        refresh();
    }, [refresh, beacons, constructionProjectId, assign, setSelectedBeaconIds]);

    const numberOfSelectedBeaconsInSite = selectedBeaconIds?.filter(i => beacons.some(b => b.id === i))?.length;

    const onUnassign = useCallback(async () => {
        if (!selectedBeaconIds) {
            return;
        }
        const beaconsToUnassign = selectedBeaconIds.filter(i => beacons.some(b => b.id === i));
        const others = selectedBeaconIds.filter(i => !beacons.some(b => b.id === i));
        await assign({ beaconIds: beaconsToUnassign });
        if (setSelectedBeaconIds) {
            setSelectedBeaconIds(others);
        }
        refresh();
    }, [refresh, beacons, constructionProjectId, assign, selectedBeaconIds, setSelectedBeaconIds]);

    return <Dropdown trigger={['click']} overlay={<Menu className="actions-menu">
        <Menu.Item>
            <Button onClick={props.startEditing} icon={<EditOutlined />}>{t('construction-site:edit')}</Button>
        </Menu.Item>
        <Menu.Item>
            <Button onClick={onUnassign} disabled={!numberOfSelectedBeaconsInSite} icon={<MinusCircleOutlined />}>{t('construction-site:unassign-beacons', { count: numberOfSelectedBeaconsInSite })}</Button>
        </Menu.Item>
        <Menu.Item>
            <Button danger type="primary" onClick={onDelete} icon={<DeleteOutlined />}>{t('construction-site:delete')}</Button>
        </Menu.Item>
    </Menu>}>
        <Button title={t('construction-project:beacons-selected', {count: numberOfSelectedBeaconsInSite})}><Badge title={t('construction-project:beacons-selected', {count: numberOfSelectedBeaconsInSite})} count={numberOfSelectedBeaconsInSite} color="#26AD23"><ActionsIcon /></Badge></Button>
    </Dropdown>
}