import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { FromToFilter } from "../api";
import { jsonDeserialize, jsonSerialize, useSetting } from "../util";
import { TimeRangePicker } from "./TimeRangePicker";


interface PredefinedFromToFilterOption {
    title: string;
    filter: FromToFilter & number;
}

interface CustomFromToFilterOption {
    title: string;
    filter: FromToFilter & { from: Date, to: Date };
}

export type FromToFilterOption = PredefinedFromToFilterOption | CustomFromToFilterOption;


const DAY = 60 * 60 * 24;
export const PredefinedFilterOptions: PredefinedFromToFilterOption[] = [
    { title: 'beacon:last-messages', filter: 3 * DAY },
    { title: 'beacon:last-day', filter: DAY },
    { title: 'beacon:last-week', filter: 7 * DAY }
];

export function useTimeRangeSelection(beaconId: string) {
    const { t } = useTranslation();
    const [customDateFilter, setCustomDateFilter] = useSetting<{ from: number, to: number } | undefined>('beacon:custom-from-to-filter:' + beaconId, undefined, jsonSerialize, jsonDeserialize);

    const [fromToFilterOptions, setFromToFilterOptions] = useState<FromToFilterOption[]>(() => {
        const options: FromToFilterOption[] = PredefinedFilterOptions.map(f => ({ title: t(f.title), filter: f.filter }));

        if (customDateFilter) {
            const from = new Date(customDateFilter.from);
            const to = new Date(customDateFilter.to);
            const custom: FromToFilterOption = { title: t('beacon:last-custom', { from: from.toLocaleString(), to: to.toLocaleDateString() === from.toLocaleDateString() ? to.toLocaleTimeString() : to.toLocaleString() }), filter: { from, to } };
            options.push(custom);
        }
        return options;
    });

    const [selectedFromToFilterOptionIndex, setSelectedFromToFilterOptionIndex] = useSetting('beacon:from-to-filter', 2);

    const onSelectCustomTimeFrame = useCallback((from: Date, to: Date) => {
        const custom: FromToFilterOption = { title: t('beacon:last-custom', { from: from.toLocaleString(), to: to.toLocaleDateString() === from.toLocaleDateString() ? to.toLocaleTimeString() : to.toLocaleString() }), filter: { from, to } };
        setFromToFilterOptions(currentOptions => {
            const newOptions = currentOptions.filter(o => typeof (o.filter) === 'number');
            newOptions.push(custom);
            return newOptions;
        });
        setSelectedFromToFilterOptionIndex(PredefinedFilterOptions.length, false);
        setCustomDateFilter({ from: from.getTime(), to: to.getTime() });
    }, [setFromToFilterOptions, setCustomDateFilter, setSelectedFromToFilterOptionIndex]);

    const onSelectFilterOption = useCallback((index: number) => {
        if (typeof (fromToFilterOptions[index].filter) === 'number') {
            setSelectedFromToFilterOptionIndex(index, true);
        } else {
            setSelectedFromToFilterOptionIndex(index, false);
        }
    }, [setSelectedFromToFilterOptionIndex, fromToFilterOptions]);

    const selectedFromToFilter = fromToFilterOptions[selectedFromToFilterOptionIndex].filter;

    return { selectedFromToFilter, fromToFilterOptions, selectedFromToFilterOptionIndex, onSelectFilterOption, onSelectCustomTimeFrame };
}

interface TimeRangeSelectionProps {
    fromToFilterOptions: FromToFilterOption[];
    selectedFromToFilterOptionIndex: number;
    onSelectFilterOption(index: number): void;
    onSelectCustomTimeFrame(from: Date, to: Date): void;
}

export function TimeRangeSelection(props: TimeRangeSelectionProps) {

    const { fromToFilterOptions, selectedFromToFilterOptionIndex, onSelectFilterOption, onSelectCustomTimeFrame } = props;

    return <Dropdown overlay={<Menu>
        {fromToFilterOptions.map((o, i) => <Menu.Item key={'filter_option_' + i} onClick={() => onSelectFilterOption(i)}>
            {typeof (o.filter) === 'number' ? o.title : <TimeRangePicker from={o.filter.from} to={o.filter.to} title={o.title} onSelected={onSelectCustomTimeFrame} />}</Menu.Item>)}
        {fromToFilterOptions.every(o => typeof (o.filter) === 'number') && <Menu.Item key="custom-filter"><TimeRangePicker onSelected={onSelectCustomTimeFrame} /> </Menu.Item>}
    </Menu>}>
        <a className='ant-dropdown-link' onClick={e => e.preventDefault()}>
            {fromToFilterOptions[selectedFromToFilterOptionIndex].title} <DownOutlined />
        </a>
    </Dropdown>
}