import React from "react";
import { Badge as AntdBadge, BadgeProps as AntdBadgeProps } from 'antd';

export function Badge(props: { children: React.ReactNode } & AntdBadgeProps) {

    const { color, children, style, ...rest } = props;
    const effectiveStyle = style || {};
    effectiveStyle.backgroundColor = color || 'red';
    return <>{props.count === 0
        ? props.children
        : <AntdBadge
            {...rest}
            style={effectiveStyle}>
            {children}
        </AntdBadge>}
    </>;
}