
import { Button, Modal, Input, Switch } from 'antd';
import { useCallback, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConstructionProject, ConstructionProjectMutableProperties, ForwardToPlatform, GeolocationResponse, LinearRing, useMyOrganization } from '../api';
import { useGetLocationOf } from '../api/api.geocoding';
import { DataAutoRefreshContext } from '../contexts/dataRefreshContext';
import { SelectArea } from './SelectArea';
import { Map as LeafletMap } from 'leaflet';
import { getDefaultMapPosition, MapPosition } from './Map';

interface ConstructionProjectPropertyModalProps {
    modalButtonIcon?: React.ReactNode;
    title: string;
    project?: ConstructionProject;
    onSave(project: ConstructionProjectMutableProperties): Promise<any>
}

export function ConstructionProjectPropertyModal(props: ConstructionProjectPropertyModalProps) {

    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [isAreaOpen, setIsAreaOpen] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const { enable: enableAutoRefresh, disable: disableAutoRefresh } = useContext(DataAutoRefreshContext);

    const [newName, setNewName] = useState<string>();
    const [newZipCode, setNewZipCode] = useState<string>();
    const [newCity, setNewCity] = useState<string>();
    const [newStreet, setNewStreet] = useState<string>();
    const [newArea, setNewArea] = useState<LinearRing | null | undefined>();
    const [newAreaWhileEditing, setNewAreaWhileEditing] = useState<LinearRing>();
    const [newForward, setNewForward] = useState<ForwardToPlatform | undefined>();
    const organization = useMyOrganization();

    const effectiveName = newName ?? props.project?.name;
    const effectiveZipCode = newZipCode ?? props.project?.address?.zipCode;
    const effectiveCity = newCity ?? props.project?.address?.city;
    const effectiveStreet = newStreet ?? props.project?.address?.street;
    const effectiveArea = newArea === null
        ? undefined
        : newArea === undefined
            ? props.project?.area
            : newArea;

    const effectiveForward = newForward ?? props.project?.forwardToPlatform;

    const [areaMap, setAreaMap] = useState<LeafletMap>();

    const cancelSelectingArea = useCallback((e: React.MouseEvent) => {
        areaMap?.resetUserSelection();
        e.preventDefault();
        setNewAreaWhileEditing(undefined);
        setIsAreaOpen(false);
    }, [setNewAreaWhileEditing, setIsAreaOpen, areaMap]);

    const finishSelectingArea = useCallback((e: React.MouseEvent) => {
        areaMap?.resetUserSelection();
        e.preventDefault();
        setNewArea(newAreaWhileEditing ? newAreaWhileEditing : null);
        setNewAreaWhileEditing(undefined);
        setIsAreaOpen(false);
    }, [newAreaWhileEditing, setNewArea, setNewAreaWhileEditing, setIsAreaOpen, areaMap]);

    const onSave = async () => {
        setIsSaving(true);
        try {
            await props.onSave({
                name: effectiveName!,
                address: effectiveZipCode || effectiveCity || effectiveStreet
                    ? {
                        zipCode: effectiveZipCode,
                        city: effectiveCity,
                        street: effectiveStreet,
                    }
                    : undefined,
                area: effectiveArea,
                forwardToPlatform: effectiveForward
            });
        } catch {
            setIsSaving(false);
            return;
        } finally {
            enableAutoRefresh();
        }
        setNewName(undefined);
        setNewZipCode(undefined);
        setNewCity(undefined);
        setNewStreet(undefined);
        setNewArea(undefined);
        setNewAreaWhileEditing(undefined);
        setNewForward(undefined);
        setIsOpen(false);
        setIsAreaOpen(false);
        setIsSaving(false);
    }

    const onCancel = (e: React.MouseEvent) => {
        e.preventDefault();
        if (isSaving) {
            return;
        }
        setNewName(undefined);
        setNewZipCode(undefined);
        setNewCity(undefined);
        setNewStreet(undefined);
        setNewArea(undefined);
        setNewAreaWhileEditing(undefined);
        setNewForward(undefined);
        setIsAreaOpen(false);
        setIsOpen(false);
        enableAutoRefresh();
    }

    const preventDefault = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
    }, []);

    const modalFooter = <div className="custom-footer" onClick={preventDefault}>
        <Button key="cancel" disabled={isSaving} onClick={onCancel}>{t('cancel')}</Button>
        <Button key="save" disabled={isSaving || !effectiveName ||
            (effectiveName === props.project?.name &&
                effectiveZipCode === props.project?.address?.zipCode &&
                effectiveCity === props.project?.address?.city &&
                effectiveStreet === props.project?.address?.street &&
                effectiveForward === props.project?.forwardToPlatform &&
                JSON.stringify(effectiveArea) === JSON.stringify(props.project?.area))}
            loading={isSaving}
            onClick={onSave}
            type="primary">
            {t('save')}
        </Button>
    </div>;

    const onOpen = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        setIsOpen(true);
        disableAutoRefresh();
    }, [setIsOpen, disableAutoRefresh]);

    const getLocationOf = useGetLocationOf();
    const [defaultMapPosition, setDefaultMapPosition] = useState<MapPosition>();
    const onOpenArea = useCallback(async () => {
        let location: GeolocationResponse | undefined = undefined;
        try {
            if (!effectiveArea) {
                const addressParts = [];
                if (effectiveStreet) {
                    addressParts.push(effectiveStreet);
                }
                const zipAndCity = `${effectiveZipCode || ''} ${effectiveCity || ''}`.trim();
                if (zipAndCity) {
                    addressParts.push(zipAndCity);
                }

                const address = addressParts.join(', ');
                if (address) {
                    location = await getLocationOf(address);
                }
            }
        }
        finally {
            if (location) {
                setDefaultMapPosition({ center: { lat: location.latitude, lng: location.longitude }, zoom: 19, bounds: undefined });
            } else {
                setDefaultMapPosition(undefined);
            }
            areaMap?.invalidateSize();
            setIsAreaOpen(true);
        }

    }, [effectiveZipCode, effectiveCity, effectiveArea, effectiveStreet, setIsAreaOpen, areaMap, setDefaultMapPosition]);


    return <>
        <Button onClick={onOpen} type="primary">{props.modalButtonIcon}{props.title}</Button>
        <Modal className="with-custom-title with-custom-footer with-custom-body" title={<div className="custom-title" onClick={preventDefault}>{props.title}</div>} visible={isOpen} onCancel={onCancel} onOk={onSave} footer={modalFooter}>
            <div className="vertical-form two-columns custom-body" onClick={preventDefault}>
                <span className="label full">{t('construction-project:name')}</span>
                <Input className="full" required value={effectiveName} onChange={e => setNewName(e.target.value)} placeholder={t('construction-project:name')} />

                <span className="label">{t('construction-project:zip-code')}</span>
                <span className="label">{t('construction-project:city')}</span>
                <Input value={effectiveZipCode} onChange={e => setNewZipCode(e.target.value)} placeholder={t('construction-project:zip-code')} />
                <Input value={effectiveCity} onChange={e => setNewCity(e.target.value)} placeholder={t('construction-project:city')} />

                <span className="label full">{t('construction-project:street')}</span>
                <Input className="label full" required value={effectiveStreet} onChange={e => setNewStreet(e.target.value)} placeholder={t('construction-project:street')} />

                <Button className="full" type="primary" onClick={onOpenArea}>{effectiveArea ? t('construction-project:change-area') : t('construction-project:select-area')}</Button>

                {organization.value?.forwardToPlatform === ForwardToPlatform.UDP && <>
                    <span className="label">{t('construction-project:forward-to-udp')}</span>
                    <div className="full">
                        <Switch checked={effectiveForward === ForwardToPlatform.UDP} onChange={c => setNewForward(c ? ForwardToPlatform.UDP : ForwardToPlatform.NONE)} checkedChildren={t('yes')} unCheckedChildren={t('no')} />
                    </div>
                </>}
            </div>
        </Modal>
        {isAreaOpen && <Modal className="hide-close-x with-custom-footer with-custom-body"
            footer={<div className="custom-footer" onClick={preventDefault}>
                <Button key="cancel" onClick={cancelSelectingArea}>{t('cancel')}</Button>
                <Button key="save" onClick={finishSelectingArea} type="primary">{t('save')}</Button>
            </div>}
            visible={isAreaOpen}
            onCancel={cancelSelectingArea}
            onOk={finishSelectingArea}
            width="75%"
            style={{ top: Math.max(16, window.innerHeight * 0.15 - 66) }}>
            <div className="custom-body" onClick={preventDefault} style={{ height: window.innerHeight * 0.7, marginTop: 0 }}>
                <SelectArea defaultMapPosition={defaultMapPosition} area={newAreaWhileEditing || effectiveArea} setArea={setNewAreaWhileEditing} constructionProjectId={props.project?.id} onMapReady={setAreaMap} />
            </div>
        </Modal>}
    </>;
}