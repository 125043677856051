import { FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { LatLng, Map, polygon } from 'leaflet';
import { useCallback, useEffect, useRef } from 'react';
import { LinearRing } from '../api';
import { useTranslation } from 'react-i18next';



function polygonToLinearRing(latLngs: LatLng[]): LinearRing {
    var area = latLngs.map(p => ({ latitude: p.lat, longitude: p.lng }));
    area.push({ latitude: area[0].latitude, longitude: area[0].longitude });
    return area;
}

interface PolygonEditControlProps {
    map?: Map;
    area?: LinearRing;
    onAreaChanged: (area?: LinearRing) => void;
}

export function PolygonEditControl(props: PolygonEditControlProps) {

    const { t } = useTranslation();
    const { map, area, onAreaChanged } = props;
    const editRef = useRef();
    const featureGroupRef = useRef<any>();

    const tooltipClick1 = t('polygon-editing:first-click');
    const tooltipClick2 = t('polygon-editing:second-click');
    const tooltipClick3 = t('polygon-editing:third-click');

    const updateEditControl = useCallback((hasPolygon: boolean) => {
        try { (editRef.current as any).remove(); } catch { }
        try { (editRef.current as any).setDrawingOptions({ polygon: !hasPolygon }); } catch { }
        try { (editRef.current as any).addTo(map); } catch { }
    }, [map, editRef]);

    useEffect(() => {
        const draw = (window.L as any)?.drawLocal?.draw;
        let previousPolygonTooltip1 = '';
        let previousPolygonTooltip2 = '';
        let previousPolygonTooltip3 = '';
        if (draw?.handlers?.polygon?.tooltip) {
            previousPolygonTooltip1 = draw.handlers.polygon.tooltip.start;
            previousPolygonTooltip2 = draw.handlers.polygon.tooltip.cont;
            previousPolygonTooltip3 = draw.handlers.polygon.tooltip.end;
            draw.handlers.polygon.tooltip.start = tooltipClick1;
            draw.handlers.polygon.tooltip.cont = tooltipClick2;
            draw.handlers.polygon.tooltip.end = tooltipClick3;
        }
        if (featureGroupRef.current) {
            if (area) {
                featureGroupRef.current.clearLayers();
                const points = area.filter((v, i) => i < area.length - 1);
                const poly = polygon(
                    points.map(p => ({ lat: p.latitude, lng: p.longitude })));
                featureGroupRef.current.addLayer(poly);
                updateEditControl(true);
            } else {
                featureGroupRef.current.clearLayers();
                updateEditControl(false);
            }
        }

        return () => {
            (editRef.current as any).remove();
            if (draw?.handlers?.polygon?.tooltip) {
                draw.handlers.polygon.tooltip.start = previousPolygonTooltip1;
                draw.handlers.polygon.tooltip.cont = previousPolygonTooltip2;
                draw.handlers.polygon.tooltip.end = previousPolygonTooltip3;
            }
        }
    }, [editRef, area, updateEditControl, featureGroupRef.current]);

    const onPolygonCreated = useCallback(async (e: any) => {
        const area = polygonToLinearRing(e.layer.getLatLngs()[0]);
        onAreaChanged(area);
        updateEditControl(true);
    }, [updateEditControl, onAreaChanged]);

    const onPolygonDeleted = useCallback((e: any) => {
        if (!e.layers.getLayers().length) {
            return;
        }
        updateEditControl(false);
        onAreaChanged(undefined);
    }, [updateEditControl, onAreaChanged]);

    const onPolygonEdited = useCallback(async (e: any) => {
        const layers = e.layers.getLayers();
        if (!layers.length) {
            return;
        }
        const area = polygonToLinearRing(layers[0].getLatLngs()[0]);
        onAreaChanged(area);
    }, [onAreaChanged]);

    return <FeatureGroup ref={featureGroupRef}>
        <EditControl onMounted={(edit: any) => { editRef.current = edit; }} onCreated={onPolygonCreated} onDeleted={onPolygonDeleted} onEdited={onPolygonEdited} draw={{ polygon: true, polyline: false, rectangle: false, circle: false, marker: false, circlemarker: false }} position="topright" />
    </FeatureGroup>;
}