import { CopyOutlined } from '@ant-design/icons';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export function CopyableText(props: { text: string }) {
    const { t } = useTranslation();
    const { text } = props;

    const copy = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        const textArea = document.createElement('textarea');
        textArea.value = text;
        textArea.setAttribute('readonly', '');
        textArea.style.position = 'absolute';
        textArea.style.left = '-9999px';
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
     }, [text]);

    if (!props.text) {
        return <></>;
    }
    return <>
        <span className="selectable">{text}</span>
        <CopyOutlined onClick={copy} title={t('copy-to-clipboard')} style={{ marginLeft: '0.5em' }} />
    </>;
}