import { useContext, useState } from "react";
import { Button } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import { useUnassignUsersFromOrganization } from '../../api';
import { Badge } from '../../components/Badge';
import { useTranslation } from "react-i18next";
import { DataRefreshContext } from "../../contexts/dataRefreshContext";
import { AssignUsersModal } from "./AssignUsersModal";

interface OrganizationUserTableFooterProps {
    organizationId: string;
    selectedUserIds: string[];
    onClearSelectedUserIds(): void;
}

export function OrganizationUserTableFooter(props: OrganizationUserTableFooterProps) {
    const { organizationId, selectedUserIds } = props;
    const [isUnassigning, setIsUnassigning] = useState(false);
    const unassignUsersFromOrganization = useUnassignUsersFromOrganization();
    const { t } = useTranslation();
    const dataRefreshContext = useContext(DataRefreshContext);

    const onUnassignUsersFromOrganization = async () => {
        try {
            setIsUnassigning(true);
            await unassignUsersFromOrganization({ userIds: selectedUserIds, organizationId });
            props.onClearSelectedUserIds();
            dataRefreshContext.refresh();
        } finally {
            setIsUnassigning(false);
        }
    };

    return <>
        <Badge count={selectedUserIds.length}>
            <Button
                danger
                disabled={selectedUserIds.length === 0}
                loading={isUnassigning}
                onClick={onUnassignUsersFromOrganization}
                title={t('organization:unassign-users-message', { count: selectedUserIds.length })}>
                <MinusCircleOutlined /> {t('organization:unassign-users', { count: selectedUserIds.length })}
            </Button>
        </Badge>

        <AssignUsersModal organizationId={organizationId} />
    </>;
}