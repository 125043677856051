import { useCallback, useContext } from "react";
import { DataAutoRefreshContext } from "../contexts/dataRefreshContext";
import { useIncrementingCounter } from "../util";

export type FromToFilter = number | { from: Date, to: Date };

export function useFromToFilter(filter: FromToFilter) {
    const dependencies = typeof (filter) === 'number'
        ? [filter.toString()] 
        : [filter.from.getTime() + '_' + filter.to.getTime()];

    return useCallback((url: string) => {
        if (typeof (filter) === 'number') {
            const to = new Date();
            const from = new Date(to.getTime() - filter * 1000);
            return url + '&from=' + from.toISOString() + '&to=' + to.toISOString();
        }
        return url + '&from=' + filter.from.toISOString() + '&to=' + filter.to.toISOString();
    }, dependencies);
}

export function useRefreshCounter() {
    const { interval, disabled } = useContext(DataAutoRefreshContext);
    return useIncrementingCounter(interval, disabled);
}