import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ErrorSeverity } from ".";
import { AuthContext } from "../contexts/authContext";

const errorCodes: { [code: string]: string } = {
    '0': 'COULD_NOT_START_OTA_AGENT',
    '1': 'NO_GNSS_POSITION',
    '2': 'NO_GNSS_CORRECTION_DATA',
    '3': 'NO_GNSS_CORRECTED_POSITION',
    '4': 'GNSS_CORRECTION_DATA_NOT_ACCEPTED',
    '5': 'INVALID_NTRIP_CREDENTIALS',
    '6': 'COULD_NOT_OPEN_SOCKET_TO_NTRIP_SERVER',
    '254': 'LOW_BATTERY'
}

export function getErrorCode(code: string) {
    const name = errorCodes[code];
    return name ? name : 'GENERIC';
}

export function useErrorTranslation() {
    const { t } = useTranslation();
    const auth = useContext(AuthContext);
    const isRoadSafetyManager = auth.currentUser?.accessToken.hasRole('road-safety-manager');

    return useCallback((code: string, severity: ErrorSeverity, resolved: boolean = false, acknowledged: boolean = false) => {

        const resolvedSuffix = resolved ? '_resolved' : '';
        const ackPrefix = acknowledged ? (t('error:acknowledged') + ': ') : '';

        const codeKey = code === '254'
            ? (severity === ErrorSeverity.Error ? 'DEAD_BATTERY' : 'LOW_BATTERY')
            : isRoadSafetyManager
                ? 'RSM'
                : getErrorCode(code);


        return `${ackPrefix}${t('error-codes:' + codeKey + resolvedSuffix)} (${code})`

    }, [t, isRoadSafetyManager]);
}