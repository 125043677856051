import { useCallback } from "react";
import { useUpdateDrawer } from "../components/beacon-drawer/BeaconDrawerContext";
import { fixDatesOfBeacons } from "./api.beacons";
import { useRefreshCounter } from "./api.utils";
import { BeaconWithPosition, ConstructionProject, ConstructionProjectMutableProperties, ConstructionSite, PagedResponse, PostConstructionSiteRequest } from "./models";
import { ApiCall, ApiState, useGetAuthorized, PagedApiState, useApiState, useAuthorizedFetch, usePagedApiState } from "./useApiState";

export function useConstructionProjects(filter?: string, archived?: boolean): PagedApiState<ConstructionProject> {
    let url = 'construction-projects';

    filter = filter?.trim();
    if (filter) {
        url += '?filter=' + filter;
    }

    if (archived !== undefined) {
        const prefix = filter ? '&' : '?';
        url += prefix + 'archived=' + archived;
    }

    return usePagedApiState(useGetAuthorized<PagedResponse<ConstructionProject>>(url));
}

export function useConstructionProject(constructionProjectId: string): ApiState<ConstructionProject | undefined> {
    return useApiState(useGetAuthorized<ConstructionProject>(`construction-projects/${constructionProjectId}`), undefined);
}

export function useCreateConstructionProject(): ApiCall<ConstructionProjectMutableProperties, ConstructionProject> {
    const authorizedFetch = useAuthorizedFetch();
    return async (constructionProject: ConstructionProjectMutableProperties) => {
        const result = await authorizedFetch('construction-projects', { method: 'post', body: JSON.stringify(constructionProject) });
        return await result.json() as ConstructionProject;
    };
}

export function useUpdateConstructionProject(constructionProjectId: string): ApiCall<ConstructionProjectMutableProperties, ConstructionProject> {
    const authorizedFetch = useAuthorizedFetch();
    return async (constructionProject: ConstructionProjectMutableProperties) => {
        const result = await authorizedFetch(`construction-projects/${constructionProjectId}`, { method: 'put', body: JSON.stringify(constructionProject) });
        return await result.json() as ConstructionProject;
    };
}

export function useDeleteConstructionProject(constructionProjectId: string): ApiCall<void, void> {
    const authorizedFetch = useAuthorizedFetch();
    return async () => {
        const result = await authorizedFetch(`construction-projects/${constructionProjectId}`, { method: 'delete' });
        if (!result.ok) {
            throw result.status;
        }
    };
}

export function useConstructionProjectBeacons(constructionProjectId: string): ApiState<BeaconWithPosition[] | undefined> {
    const refreshCounter = useRefreshCounter();
    const get = useGetAuthorized<BeaconWithPosition[]>(`construction-projects/${constructionProjectId}/beacons`, fixDatesOfBeacons);
    const beacons = useApiState(get, undefined, refreshCounter);
    useUpdateDrawer(beacons);
    return beacons;
}

export function useAssignBeaconsToConstructionProject(): ApiCall<{ beaconIds: string[], constructionProjectId: string }, void> {
    const authorizedFetch = useAuthorizedFetch();
    return async (a: { beaconIds: string[], constructionProjectId: string }) => {
        const result = await authorizedFetch(`construction-projects/${a.constructionProjectId}/beacons`, { method: 'post', body: JSON.stringify(a.beaconIds) });
        if (!result.ok) {
            throw result.status;
        }
    };
}

export function useUnassignBeaconsFromConstructionProject(): ApiCall<{ beaconIds: string[], constructionProjectId: string }, void> {
    const authorizedFetch = useAuthorizedFetch();
    return async (a: { beaconIds: string[], constructionProjectId: string }) => {
        const result = await authorizedFetch(`construction-projects/${a.constructionProjectId}/beacons`, { method: 'delete', body: JSON.stringify(a.beaconIds) });
        if (!result.ok) {
            throw result.status;
        }
    };
}

export function useCreateConstructionSite(constructionProjectId: string): ApiCall<PostConstructionSiteRequest, void> {
    const authorizedFetch = useAuthorizedFetch();
    return async (constructionSite: PostConstructionSiteRequest) => {
        const result = await authorizedFetch(`construction-projects/${constructionProjectId}/construction-sites`, { method: 'post', body: JSON.stringify(constructionSite) });
        if (!result.ok) {
            throw result.status;
        }
    };
}

export function useAssignConstructionSite(constructionProjectId: string): ApiCall<{ constructionSiteId?: string, beaconIds: string[], exclusive?: boolean }, void> {
    const authorizedFetch = useAuthorizedFetch();
    return async (assignment: { constructionSiteId?: string, beaconIds: string[], exclusive?: boolean }) => {
        const url = assignment.constructionSiteId
            ? `construction-projects/${constructionProjectId}/construction-sites/${assignment.constructionSiteId}${(assignment.exclusive === true ? '?exclusive=true' : '')}`
            : `construction-projects/${constructionProjectId}/construction-sites/none`;
        const result = await authorizedFetch(url, { method: 'put', body: JSON.stringify(assignment.beaconIds) });
        if (!result.ok) {
            throw result.status;
        }
    };
}

export function useConstructionProjectSites(constructionProjectId: string): ApiState<ConstructionSite[] | undefined> {
    const refreshCounter = useRefreshCounter();
    const fixDates = useCallback((sites: ConstructionSite[]) => {
        sites.forEach(site => {
            site.createdAt = new Date(site.createdAt);
        });
        return sites;
    }, []);
    const get = useGetAuthorized<ConstructionSite[]>(`construction-projects/${constructionProjectId}/construction-sites`, fixDates);
    return useApiState(get, undefined, refreshCounter);
}


export function useGetConstructionProjectSite(): ApiCall<{ constructionProjectId: string; constructionSiteId: string; }, ConstructionSite> {
    const authorizedFetch = useAuthorizedFetch();
    return async (ids: { constructionProjectId: string; constructionSiteId: string; }) => {
        const url = `construction-projects/${ids.constructionProjectId}/construction-sites/${ids.constructionSiteId}`
        const result = await authorizedFetch(url, { method: 'get' });
        if (!result.ok) {
            throw result.status;
        }
        const site = (await result.json()) as ConstructionSite;
        site.createdAt = new Date(site.createdAt);
        return site;
    };
}
