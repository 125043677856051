import { useCallback, useContext, useState } from "react";
import { Button, Dropdown, Menu } from 'antd';
import { ExpandOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { ConstructionSite, useAssignConstructionSite, useUnassignBeaconsFromConstructionProject } from '../../api';
import { Badge } from '../../components/Badge';
import { useTranslation } from "react-i18next";
import { DataRefreshContext } from "../../contexts/dataRefreshContext";
import { AssignBeaconsModal } from "./AssignBeaconsModal";
import { MapPosition } from "../../components/Map";

interface ConstructionProjectBeaconTableFooterProps {
    constructionProjectId: string;
    selectedBeaconIds: string[];
    constructionSites?: ConstructionSite[];
    onClearSelectedBeaconIds(): void;
    reload: () => void;
    defaultMapPositionForBeaconAssignments?: MapPosition;
}

export function ConstructionProjectBeaconTableFooter(props: ConstructionProjectBeaconTableFooterProps) {
    const { constructionProjectId, selectedBeaconIds } = props;
    const [isUnassigning, setIsUnassigning] = useState(false);
    const unassignBeaconsFromConstructionProject = useUnassignBeaconsFromConstructionProject();
    const { t } = useTranslation();
    const dataRefreshContext = useContext(DataRefreshContext);
    const assignToConstructionSite = useAssignConstructionSite(props.constructionProjectId);
    const onAssignToConstructionSite = useCallback(async (constructionSiteId?: string)=>{
        await assignToConstructionSite({constructionSiteId, beaconIds: selectedBeaconIds});
        props.reload();
    }, [selectedBeaconIds, assignToConstructionSite, props.reload]);
    const onUnassignBeaconsFromConstructionProject = async () => {
        try {
            setIsUnassigning(true);
            await unassignBeaconsFromConstructionProject({ beaconIds: selectedBeaconIds, constructionProjectId });
            props.onClearSelectedBeaconIds();
            dataRefreshContext.refresh();
        } finally {
            setIsUnassigning(false);
        }
    };

    return <>
        <Badge count={selectedBeaconIds.length}>
            <Button
                danger
                disabled={selectedBeaconIds.length === 0}
                loading={isUnassigning}
                onClick={onUnassignBeaconsFromConstructionProject}
                title={t('construction-project:unassign-beacons-message', { count: selectedBeaconIds.length })}>
                <MinusCircleOutlined /> {t('construction-project:unassign-beacons', { count: selectedBeaconIds.length })}
            </Button>
        </Badge>

        <AssignBeaconsModal constructionProjectId={constructionProjectId} defaultMapPosition={props.defaultMapPositionForBeaconAssignments} />
        <Dropdown disabled={selectedBeaconIds.length === 0} trigger={['click']} overlay={<Menu className="actions-menu">
            <Menu.Item key="0" onClick={() => onAssignToConstructionSite(undefined)}>{t('construction-project:assign-to-no-site')}</Menu.Item>
            {props.constructionSites?.map((c,i) => <Menu.Item key={i+1} onClick={() => onAssignToConstructionSite(c.id)}>{c.name}</Menu.Item>)}
        </Menu>}>
            <Button
                disabled={selectedBeaconIds.length === 0}
                loading={isUnassigning}
                title={t('construction-project:assign-to-other-site-message', { count: selectedBeaconIds.length })}>
                <ExpandOutlined /> {t('construction-project:assign-to-other-site', { count: selectedBeaconIds.length })}
            </Button>
        </Dropdown>
    </>;
}