import { useCallback, useState } from "react";

export function jsonSerialize<T>(item: T){
    if(item === undefined || item === null){
        return "";
    }
    return JSON.stringify(item);
}

export function jsonDeserialize<T>(json: string){
    if(json === undefined || json === null || json === ""){
        return undefined;
    }
    return JSON.parse(json) as T;
}

export function useSetting<T = number | string>(name: string, defaultValue: T, serialize?: (item: T) => string, deserialize?: (json: string) => T | undefined): [T, (v: T, persist?: boolean) => void] {
    const key = 'setting:' + name;

    const onSerialize = useCallback((item: T)=>{
        if(serialize){
            return serialize(item);
        }

        return (item as any as number | string).toString();
    }, []);

    const onDeserialize = useCallback((json: string)=>{
        if(deserialize){
            const deserialized = deserialize(json);
            if(deserialized === undefined){
                return defaultValue;
            }
            return deserialized;
        }

        return (typeof (defaultValue) === 'number' ? parseFloat(json) : json) as any as T;
    }, [defaultValue]);

    const [value, setValue] = useState<T>(() => {
        const storedValue = tryRead(key);
        if (storedValue === undefined || storedValue === null) {
            tryStore(key, defaultValue, onSerialize);
            return defaultValue;
        } else {
            return onDeserialize(storedValue);
        }
    });

    const setAndStoreValue = useCallback((v: T, persist?: boolean) => {
        setValue(v);
        if (persist !== false) {
            tryStore(key, v, onSerialize);
        }
    }, [setValue, key, onSerialize]);

    return [value, setAndStoreValue];
}

function tryRead(key: string) {
    try {
        return window.localStorage.getItem(key);
    } catch {
        return undefined;
    }
}

function tryStore<T>(key: string, v: T, serialize: (item: T) => string) {
    try {
        window.localStorage.setItem(key, serialize(v));
    } catch { }
}
