import React, { useCallback, useEffect, useRef, useState } from "react";

interface Dimensions { width: number; height: number; }
const initialState: Dimensions = { width: 0, height: 0 };

export function useDimension<T extends Element>(ref: React.MutableRefObject<T>) {
    const [dimensions, setDimensions] = useState(initialState);
    const [handlers, setHandlers] = useState<((d: Dimensions) => void)[]>([]);
    const setHandler = useCallback((handler: (d: Dimensions) => void) => {
        if (handlers.length) {
            handlers[0] = handler;
        } else {
            handlers.push(handler);
        }
    }, []);
    const resizeObserverRef = useRef<ResizeObserver>();

    useEffect(() => {
        try {
            resizeObserverRef.current = new ResizeObserver((entries = []) => {
                entries.forEach((entry) => {
                    const { width, height } = entry.contentRect;
                    const d = { width, height };
                    setDimensions(d);
                    handlers.forEach(h => h(d));
                });
            });
            if (ref.current) resizeObserverRef.current.observe(ref.current);
        }
        catch { }
        return () => {
            try {
                if (resizeObserverRef.current) {
                    resizeObserverRef.current.disconnect();
                    setDimensions(initialState);
                    setHandlers([]);
                }
            } catch { }
        };
    }, [ref.current]);
    return {
        ...dimensions,
        setHandler
    };
};