import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Breadcrumb, Spin, Tag } from 'antd';
import { useBeacon, useConstructionProject } from '../../api';
import { BeaconManagementMenu } from '../../components/BeaconManagementMenu';
import { BeaconDetails } from '../../components/BeaconDetails';

export function ConstructionProjectBeacon() {
    const { t } = useTranslation();
    const history = useHistory();
    const { constructionProjectId, beaconId } = useParams<{ constructionProjectId: string, beaconId: string }>();
    const constructionProject = useConstructionProject(constructionProjectId);
    const beacon = useBeacon(beaconId);

    if (constructionProject.loading || !constructionProject.value || beacon.loading || !beacon.value) {
        return <Spin size='large' />;
    }
    const constructionProjectUrl = '/construction-projects/' + constructionProjectId;

    return <BeaconDetails beacon={beacon.value} constructionProjectId={constructionProjectId} breadcrumb={<Breadcrumb>
        <Breadcrumb.Item><a href='/construction-projects' onClick={e => { e.preventDefault(); history.push('/construction-projects') }}>{t('construction-projects')}</a></Breadcrumb.Item>
        <Breadcrumb.Item><a href={constructionProjectUrl} onClick={e => { e.preventDefault(); history.push(constructionProjectUrl) }}>{constructionProject.value.name}</a></Breadcrumb.Item>
    <Breadcrumb.Item>{beacon.value ? (beacon.value.serial || beacon.value.id) : beaconId}{beacon.value && <BeaconManagementMenu style={{ marginLeft: '0.5em', border: '1px solid black', borderRadius: 20, padding: 4 }} beacon={beacon.value} onUpdated={beacon.refresh} />}{beacon.value.isDisabled && <Tag color='red' style={{ marginLeft: '1em' }}>{t('beacon:disabled')}</Tag>}</Breadcrumb.Item>
    </Breadcrumb>} />;
}
