import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { PlusCircleOutlined } from '@ant-design/icons';
import { BeaconWithPosition, useUnassignBeaconsFromConstructionProject } from "../../api";
import { SelectBeaconsModal } from "../../components/SelectBeaconsModal";
import { DataRefreshContext } from "../../contexts/dataRefreshContext";

interface UnassignBeaconsModalProps {
    constructionProjectId: string;
    beacons: BeaconWithPosition[];
    title?: string;
}

export function UnassignBeaconsModal(props: UnassignBeaconsModalProps) {
    const { constructionProjectId } = props;
    const { t } = useTranslation();
    const unassignBeaconsFromConstructionProject = useUnassignBeaconsFromConstructionProject();
    const dataRefreshContext = useContext(DataRefreshContext);

    const onUnassignBeaconsFromConstructionProject = async (beaconIds: string[]) => {
        await unassignBeaconsFromConstructionProject({ beaconIds, constructionProjectId });
        dataRefreshContext.refresh();
    }

    return <SelectBeaconsModal
        title={props.title ?? t('construction-project:unassign-beacons')}
        instruction={t('construction-project:unassign-beacons-drawing-tooltip')}
        beacons={props.beacons}
        selectButtonText={beaconIds => t('construction-project:unassign-beacons', { count: beaconIds.length })}
        selectButtonTitle={beaconIds => t('construction-project:unassign-beacons-message', { count: beaconIds.length })}
        modalButtonIcon={<PlusCircleOutlined />}
        onBeaconsSelected={onUnassignBeaconsFromConstructionProject} />
}