import { groupBy, useTransformedState } from ".";
import { BeaconWithPosition } from "../api";

export function useBeaconsByConstructionSite(beacons: BeaconWithPosition[] | undefined): Map<string, BeaconWithPosition[]>{
    return useTransformedState(beacons,
        unmapped => {
            const grouped = groupBy(unmapped ?? [], b => b.constructionSiteId);
            const mapped = new Map<string, BeaconWithPosition[]>();
            grouped.forEach(g => {
                if (g.key) {
                    mapped.set(g.key, g.items);
                }
            });
            return mapped;
        });
}