import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './en.json';
import de from './de.json';

const options: InitOptions = {
  resources: { en, de } as any,
  fallbackLng: 'de',
  defaultNS: 'translations',
  debug: true,
  interpolation: {
    escapeValue: false
  },
  react: {
    wait: false,
    bindI18n: 'languageChanged loaded',
    bindI18nStore: 'added removed',
    nsMode: 'default'
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(options);

export default i18n;