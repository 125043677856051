import { Dashboard } from './screens/dashboard/Dashboard';
import { CurrentUser, Role } from './contexts/authContext';
import { Beacons } from './screens/beacons/Beacons';
import { Organiztations } from './screens/organizations/Organizations';
import { Organization } from './screens/organization/Organization';
import { Users } from './screens/users/Users';
import { NoRole } from './screens/no-role/NoRole';
import { Beacon } from './screens/beacons/Beacon';
import { ConstructionProjects } from './screens/construction-projects/ConstructionProjects';
import { ConstructionProject } from './screens/construction-project/ConstructionProject';
import { ConstructionProjectBeacon } from './screens/construction-project/ConstructionProjectBeacon';

interface Route {
    path: string;
    component: () => JSX.Element;
    navigationMenu?: { labelKey: string };
    roles?: Role[];
};

const allRoutes: Route[] = [
    {
        path: '/construction-projects',
        component: ConstructionProjects,
        navigationMenu: {
            labelKey: 'construction-projects'
        },
        roles: ['road-safety-manager', 'solution-operator']
    },
    {
        path: '/construction-projects/:constructionProjectId',
        component: ConstructionProject,
        roles: ['road-safety-manager', 'solution-operator']
    },
    {
        path: '/construction-projects/:constructionProjectId/beacons/:beaconId',
        component: ConstructionProjectBeacon,
        roles: ['road-safety-manager', 'solution-operator']
    },
    {
        path: '/beacons',
        component: Beacons,
        navigationMenu: {
            labelKey: 'beacons'
        },
        roles: ['solution-operator', 'road-safety-manager']
    },
    {
        path: '/beacons/:beaconId',
        component: Beacon,
        roles: ['solution-operator', 'road-safety-manager']
    },
    {
        path: '/organizations',
        component: Organiztations,
        navigationMenu: {
            labelKey: 'organizations'
        },
        roles: ['solution-operator', 'user-administrator']
    },
    {
        path: '/organizations/:organizationId',
        component: Organization,
        roles: ['solution-operator', 'user-administrator']
    },
    {
        path: '/users',
        component: Users,
        navigationMenu: {
            labelKey: 'users'
        },
        roles: ['user-administrator']
    }
];

export function routes(user: CurrentUser): Route[] {

    if (user.accessToken.payload['cognito:groups'].length === 0) {
        return [
            {
                path: '/',
                component: NoRole
            }
        ];
    }

    return allRoutes.filter(route => !route.roles?.length || route.roles.some((role) => user.accessToken.payload['cognito:groups'].indexOf(role) >= 0));
}