import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AmplifyLoadingSpinner } from '@aws-amplify/ui-react';
import { Table, Tag } from 'antd';
import { MailOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { ForwardToPlatform, Organization, OrganizationMutableProperties, useCreateOrganization, useOrganizations } from "../../api";
import { toAntdPagination } from "../../util";
import { OrganizationPropertyModal } from "../../components/OrganizationPropertyModal";

export function Organiztations() {

    const history = useHistory();
    const { t } = useTranslation();

    const organiztations = useOrganizations();
    const createOrganization = useCreateOrganization();

    const onCreateOrganization = async (org: OrganizationMutableProperties) => {
        const organization = await createOrganization(org);
        history.push('/organizations/' + organization.id);
    };

    if (!organiztations.loading && !organiztations.value) {
        return <AmplifyLoadingSpinner />;
    }

    return <Table
        sticky
        dataSource={organiztations.value?.items || []}
        rowKey="id"
        size="middle"
        loading={organiztations.loading}
        onRow={record => {
            return {
                onClick: event => {
                    history.push('/organizations/' + record.id);
                },
                style: {
                    cursor: 'pointer'
                }
            };
        }}
        columns={[
            {
                title: t('organization:name'),
                dataIndex: 'name', 
                sorter: (a: Organization, b: Organization) => a.name.localeCompare(b.name),
               
            },
        ]}
        pagination={toAntdPagination(organiztations)}
        footer={() => <OrganizationPropertyModal title={t('organization:new')} modalButtonIcon={<PlusCircleOutlined />} onSave={onCreateOrganization} />}>
    </Table>;
}