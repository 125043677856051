import React from 'react';
import Auth from '@aws-amplify/auth';
import { CognitoAccessToken } from 'amazon-cognito-identity-js';

export type Role = 'user-administrator' | 'solution-operator' | 'road-safety-manager';

export interface TokenPaylod {
    "cognito:groups": Role[];
    exp: number;
}

export interface Token {
    jwt: string;
    payload: TokenPaylod;
    expiresAt: Date;
    isExpired: boolean;
    refreshIfExpired(): Promise<void>;
    hasRole(role: Role): boolean;
}

export interface CurrentUser {
    email: string;
    accessToken: Token;
}

export class AccessToken implements Token {

    jwt!: string;
    payload!: TokenPaylod;
    expiresAt!: Date;

    constructor(cognitoAccessToken: CognitoAccessToken) {
        this.intitialize(cognitoAccessToken);
    }

    get isExpired() {
        return new Date() >= this.expiresAt;
    }

    async refreshIfExpired() {
        if (!this.isExpired) {
            return;
        }

        const session = await Auth.currentSession();
        this.intitialize(session.getAccessToken());
    }

    hasRole(role: Role): boolean {
        return this.payload['cognito:groups'].indexOf(role) >= 0;
    }

    private intitialize(cognitoAccessToken: CognitoAccessToken) {
        const accessTokenPayload = cognitoAccessToken.decodePayload() as TokenPaylod;
        this.jwt = cognitoAccessToken.getJwtToken();
        this.payload = accessTokenPayload;
        this.expiresAt = new Date(accessTokenPayload.exp * 1000);

        if(!this.payload['cognito:groups']){
            this.payload['cognito:groups'] = [];
        }
    }
}

export const AuthContext = React.createContext<{ currentUser?: CurrentUser }>({});