import { useCallback } from "react";
import { FromToFilter, useFromToFilter, useRefreshCounter } from "./api.utils";
import { BeaconEnvironment, BeaconPosition } from "./models";
import { ApiState, useApiState, useGetAuthorized } from "./useApiState";

function useHistory<TMessage extends { measuredAt: Date }>(constructionProjectId: string | undefined, beaconId: string, fromToFilter: FromToFilter, type: 'POS' | 'ENV'): ApiState<TMessage[] | undefined> {
    const refreshCounter = useRefreshCounter();
    const fixDates = useCallback((positions: TMessage[]) => {
        if (!Array.isArray(positions)) {
            return [];
        }
        positions.forEach(p => {
            p.measuredAt = new Date(p.measuredAt);
        });

        return positions;
    }, []);
    const appendDateFilter = useFromToFilter(fromToFilter);

    const url = constructionProjectId
        ? `construction-projects/${constructionProjectId}/beacons/${beaconId}/history?messageType=${type}&take=1000`
        : `beacons/${beaconId}/history?messageType=${type}&take=10000`;

    const get = useGetAuthorized<TMessage[]>(url, fixDates, appendDateFilter);
    return useApiState(get, undefined, refreshCounter);
}

export function usePositionHistory(constructionProjectId: string | undefined, beaconId: string, fromToFilter: FromToFilter): ApiState<BeaconPosition[] | undefined> {
    return useHistory(constructionProjectId, beaconId, fromToFilter, 'POS');
}

export function useEnvironmentHistory(constructionProjectId: string | undefined, beaconId: string, fromToFilter: FromToFilter): ApiState<BeaconEnvironment[] | undefined> {
    return useHistory(constructionProjectId, beaconId, fromToFilter, 'ENV');
}