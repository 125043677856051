import { Button, Dropdown, Menu, Modal } from 'antd';
import { DeleteOutlined, DownOutlined, EditOutlined, QuestionCircleOutlined, ReloadOutlined, WarningOutlined } from '@ant-design/icons';
import { ConstructionProjectPropertyModal } from '../../components/ConstructionProjectPropertyModal';
import { ConstructionProject, ConstructionProjectMutableProperties, useDeleteConstructionProject, useUpdateConstructionProject } from '../../api';
import { useTranslation } from 'react-i18next';
import React, { useCallback } from 'react';
import { Badge } from '../../components/Badge';
import { ActionsIcon } from '../../components/ActionsIcon';

interface ActionsDropdownProps {
    constructionProjectId: string;
    constructionProject?: ConstructionProject;
    additionalActions?: React.ReactNode[];
    badgeCount?: number;
    refresh(): void;
    onIsDeletingOrArchiving(is: boolean): void;
    onDeleted(): void;
}

export function ActionsDropdown(props: ActionsDropdownProps) {

    const { constructionProject, constructionProjectId, refresh, onIsDeletingOrArchiving, onDeleted, additionalActions } = props;
    const { t } = useTranslation();

    const deleteContructionProject = useDeleteConstructionProject(constructionProjectId);
    const update = useUpdateConstructionProject(constructionProjectId);

    const onDelete = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        Modal.confirm({
            type: 'warn',
            icon: <WarningOutlined style={{ color: 'red' }} />,
            title: t('construction-project:confirm-delete'),
            content: t('construction-project:confirm-delete-details'),
            okText: t('construction-project:confirm-delete-yes'),
            okButtonProps: { danger: true },
            cancelText: t('cancel'),
            onOk: async () => {
                onIsDeletingOrArchiving(true);
                try {
                    await deleteContructionProject();
                    onDeleted();
                }
                finally {
                    onIsDeletingOrArchiving(false);
                }
            }
        })
    }, [onIsDeletingOrArchiving, deleteContructionProject, onDeleted]);

    const useUpdateArchivedState = (to: boolean) => {
        return useCallback((e: React.MouseEvent) => {
            e.preventDefault();
            Modal.confirm({
                type: 'warn',
                icon: to ? <WarningOutlined /> : <QuestionCircleOutlined />,
                title: to ? t('construction-project:confirm-archive') : t('construction-project:confirm-dearchive'),
                content: to ? t('construction-project:confirm-archive-details') : t('construction-project:confirm-dearchive-details'),
                okText: to ? t('construction-project:confirm-archive-yes') : t('construction-project:confirm-dearchive-yes'),
                okButtonProps: { danger: to },
                cancelText: t('cancel'),
                onOk: async () => {
                    if (!constructionProject) {
                        return;
                    }
                    onIsDeletingOrArchiving(true);
                    try {
                        await update({
                            name: constructionProject.name,
                            area: constructionProject.area,
                            address: constructionProject.address,
                            forwardToPlatform: constructionProject.forwardToPlatform,
                            isArchived: to
                        });
                        refresh();
                    }
                    finally {
                        onIsDeletingOrArchiving(false);
                    }
                }
            })
        }, [onIsDeletingOrArchiving, update, refresh, constructionProject]);
    };

    const onArchive = useUpdateArchivedState(true);
    const onDearchive = useUpdateArchivedState(false);

    const onSaveEditing = useCallback(async (project: ConstructionProjectMutableProperties) => {
        await update(project);
        refresh();
    }, [update, refresh]);

    return <Dropdown trigger={['click']} overlay={<Menu className="actions-menu">
        <Menu.Item key="edit">
            <ConstructionProjectPropertyModal onSave={onSaveEditing} modalButtonIcon={<EditOutlined />} title={t('edit')} project={constructionProject} />
        </Menu.Item>
        {additionalActions?.length && additionalActions}
        {!constructionProject?.isArchived && <Menu.Item key="archive">
            <Button danger type="primary" onClick={onArchive}><DeleteOutlined /> {t('archive')}</Button>
        </Menu.Item>}
        {constructionProject?.isArchived && <Menu.Item key="dearchive">
            <Button danger type="primary" onClick={onDearchive}><ReloadOutlined /> {t('dearchive')}</Button>
        </Menu.Item>}
        {constructionProject?.isArchived && <Menu.Item key="delete">
            <Button danger type="primary" onClick={onDelete}><DeleteOutlined /> {t('delete')}</Button>
        </Menu.Item>}
    </Menu>}>
        <div>
            <Badge count={props.badgeCount ?? 0}>
                <Button>
                    <ActionsIcon />
                </Button>
            </Badge>
        </div>
    </Dropdown>;
}